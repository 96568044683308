import React from 'react'
import Loadable from 'react-loadable'
import {Loading} from './components'

export const routes: RouteData[] = [
  {
    path: '/jdAuth',
    hidden: true,
    auther: true,
    label: '京东预授权',
    key: 'jdAuth',
    component: Loadable({
      loader: () => import('./pages/JdAuth'),
      loading: () => <Loading />
    })
  },
  {
    path: '/dong',
    hidden: true,
    auther: true,
    label: '机器人',
    key: 'dong',
    component: Loadable({
      loader: () => import('./pages/Dong'),
      loading: () => <Loading />
    })
  },
  {
    path: '/auth/reset',
    hidden: true,
    auther: true,
    component: Loadable({
      loader: () => import('./pages/Auth'),
      loading: () => <Loading />,
      render(loaded: any, props: any) {
        const Auth = loaded.default
        return (
          <Auth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            reset
          />
        )
      }
    })
  },
  {
    path: '/auth/error/:message?',
    hidden: true,
    auther: true,
    component: Loadable({
      loader: () => import('./pages/Auth'),
      loading: () => <Loading />,
      render(loaded: any, props: any) {
        const Auth = loaded.default
        return (
          <Auth
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            error
          />
        )
      }
    })
  },
  {
    path: '/auth/:token?',
    hidden: true,
    auther: true,
    component: Loadable({
      loader: () => import('./pages/Auth'),
      loading: () => <Loading />
    })
  },
  {
    path: '/dashboard',
    icon: 'dashboard',
    exact: true,
    label: '数据看板',
    key: 'dashboard',
    component: Loadable({
      loader: () => import('./pages/Dashboard'),
      loading: () => <Loading />
    })
  },
  {
    path: '/qa/:groupCode?/:sceneType?/:action?/:code?',
    icon: 'question-circle',
    label: '智能问答',
    key: 'qa',
    // exact: false,
    component: Loadable({
      loader: () => import('./pages/QA'),
      loading: () => <Loading />
    })
  },
  {
    path: '/settings',
    icon: 'question-circle',
    label: '店铺设置',
    key: 'settings',
    // exact: false,
    component: Loadable({
      loader: () => import('./pages/Settings'),
      loading: () => <Loading />
    })
  },
  {
    path: '/dong',
    hidden: true,
    auther: true,
    label: '机器人',
    key: 'dong',
    component: Loadable({
      loader: () => import('./pages/Dong'),
      loading: () => <Loading />
    })
  },
  {
    path: '/hukou/:action?',
    hidden: true,
    auther: true,
    label: '户口',
    key: 'hukou',
    component: Loadable({
      loader: () => import('./pages/Hukou'),
      loading: () => <Loading />
    })
  }
]

export default routes
