import reset from './reset'

const codeHandler = (resp: any) => {
  const {data} = resp
  const code = `${data.code}`
  if (code === '0') {
    return data
  }
  switch (code) {
    case '-103':
      reset('授权签名不合法')
      break
    default:
      break
  }
  throw new Error(data.message)
}

export default codeHandler
