function withStorage(storage) {
  return (Cache) => {
    class StorageCache extends Cache {
      constructor(key) {
        super(storage, key)
      }
    }

    return StorageCache
  }
}

export default withStorage
