export const AUTH_TAG = 'token'
export const HUKOU_AUTH_TAG = 'hukouToken'
const REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST = process.env.REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST || ''
// eslint-disable-next-line max-len
export const JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST: string[] = REACT_APP_JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST.split(
  ','
)
export const ZENUS_CALLBACK_ENDPOINT = process.env.REACT_APP_ZENUS_CALLBACK_ENDPOINT as string
export const CMS_URL = `https://open-oauth.jd.com/oauth2/to_login?app_key=448328D8FBE152A64D4D9CBA68E02A09&response_type=code&redirect_uri=${encodeURIComponent(
  'https://jd.leyantech.com/#/jdAuth'
)}&state=leyan_merchant${+new Date()}&scope=snsapi_base`
export const NEW_CMS_URL = `https://open-oauth.jd.com/oauth2/to_login?app_key=612D828330A0357D432B05E93CE70DD8&response_type=code&redirect_uri=${encodeURIComponent(
  ZENUS_CALLBACK_ENDPOINT
)}&state=leyan_merchant${+new Date()}&scope=snsapi_base`
export default {
  AUTH_TAG,
  JD_DONGDONG_PLUGIN_DEBUG_PIN_WHITE_LIST,
  CMS_URL,
  NEW_CMS_URL,
  HUKOU_AUTH_TAG
}
