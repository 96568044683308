import {jdClient} from './clients'

export function updateSettings(settings: any) {
  return jdClient.post('/shopkeeper/v1/shops', settings)
}
export function fetchSettings() {
  return jdClient.get('/shopkeeper/v1/shops')
}

export default {updateSettings, fetchSettings}
