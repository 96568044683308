import {decode} from 'jsonwebtoken'

import tokenCache from 'services/cache/token'
import {authorize as authorizeApi} from 'services/api'
import reducer from 'services/store/profile'
import {tokenSelector, authorizedSelector} from 'services/store/profile/selectors'

export function unauthorize() {
  return (dispatch) => {
    tokenCache.remove()
    dispatch(
      reducer.actions.update({
        token: null,
        user: null
      })
    )
  }
}

export function authorize(token) {
  return (dispatch, getState) => {
    const state = getState()
    const prevToken = tokenSelector(state)
    const authorized = authorizedSelector(state)

    if (!token) {
      dispatch(unauthorize())

      return Promise.resolve(false)
    }

    if (authorized && token === prevToken) {
      return Promise.resolve(false)
    }

    return Promise.resolve()
      .then(() => {
        const {leyan_user: {username, roles} = {}, exp} = decode(token)

        if (exp * 1e3 < Date.now()) {
          dispatch(unauthorize())

          return false
        }

        const user = {
          username,
          roles
        }

        tokenCache.set(token)
        authorizeApi(token)

        dispatch(
          reducer.actions.update({
            token,
            user
          })
        )

        return true
      })
      .catch((err) => {
        dispatch(reducer.actions.update.error(err))
      })
  }
}
