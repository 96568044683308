import {jdClient} from 'api/clients'
import {AUTH_TAG} from '../CONST'

export const READ_AUTH_TOKEN = 'READ_AUTH_TOKEN'
export const WRITE_AUTH_TOKEN = 'WRITE_AUTH_TOKEN'
export const RESET_AUTH_TOKEN = 'RESET_AUTH_TOKEN'
export const initialAuthState = {
  auth: null
}
export const authReducer: IReducer = (state, action) => {
  switch (action.type) {
    case READ_AUTH_TOKEN:
      return {
        ...state,
        [AUTH_TAG]: localStorage.getItem(AUTH_TAG)
      }
    case WRITE_AUTH_TOKEN:
      if (action.token) {
        jdClient.defaults.headers.Authorization = action.token
        window.localStorage.setItem(AUTH_TAG, action.token)
      }
      return {
        ...state,
        [AUTH_TAG]: localStorage.getItem(AUTH_TAG)
      }
    case RESET_AUTH_TOKEN:
      window.localStorage.removeItem(AUTH_TAG)
      return {
        ...state,
        [AUTH_TAG]: null
      }
    default:
      return state
  }
}

export default authReducer
