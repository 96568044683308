import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import {ConfigProvider} from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'
import App from './App'
import {rootReducer, initialRootState} from './reducer'
import {StateProvider} from './hooks/useGlobalState'

import * as serviceWorker from './serviceWorker'

moment.locale('zh-cn')
ReactDOM.render(
  <ConfigProvider locale={locale}>
    <StateProvider
      initialState={initialRootState}
      reducer={rootReducer}
    >
      <App />
    </StateProvider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
