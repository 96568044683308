import React, {useState} from 'react'
import {Modal, Button, Icon} from 'antd'
import moment from 'moment'
import styles from './styles.module.scss'

// 老京东下线弹窗
export const NO_SERVICE_TIME = Number(process.env.REACT_APP_NO_SERVICE_TIME)
export const OFFLINE_TIME = Number(process.env.REACT_APP_OFFLINE_TIME)
export const NO_SERVICE_DATE_STR = moment(NO_SERVICE_TIME).format('M月DD日 HH时')

export default function OfflineModal() {
  const isOffline = Date.now() < NO_SERVICE_TIME
  const isWill = isOffline ? '将' : '已'
  const [isModalVisible, setIsModalVisible] = useState(isOffline)
  return (
    <Modal
      visible={isModalVisible}
      className={styles.modal}
      width={400}
      centered
      footer={false}
      closable={false}
    >
      <div>
        <h3 className={styles.header}>
          <Icon type="info-circle" />
          &nbsp;亲爱的用户,插件
          {isWill}
          于
          <span>{NO_SERVICE_DATE_STR}</span>
          下线
        </h3>

        <div className={styles.content}>
          <p>
            为保障后续服务能力升级，旧插件 “乐语助人”
            {' '}
            {isWill}
            于 2021.02.26 全面下线，
            请您尽快切换使用新插件 “言准智能客服”；如遇到任何问题，请尽快在您的微信服务群中反馈，我们将全力为您解决迁移的事宜。
          </p>
          <p>
            “乐语助人”插件下线后不可用，为保证您的服务工作不受影响，请尽早切换至新插件！
          </p>
        </div>
        <div className={styles.btn}>
          <Button
            onClick={() => setIsModalVisible(false)}
            type="primary"
          >
          我已知晓
          </Button>
        </div>

      </div>
    </Modal>
  )
}
