import React, {FunctionComponent} from 'react'
import {Spin} from 'antd'

const Loading: FunctionComponent = () => (
  <div
    style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'
    }}
  >
    <Spin spinning />
  </div>
)

export default Loading
