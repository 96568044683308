import React, {FunctionComponent} from 'react'
import {
  Layout
} from 'antd'
import styles from './Page.module.scss'

const {Content} = Layout

interface IPageProps {
  style?: React.CSSProperties
}

const Page: FunctionComponent<IPageProps> = (props) => {
  const {
    style,
    children
  } = props

  return (
    <Content
      className={styles.page}
      style={style}
    >
      {children}
    </Content>
  )
}

export default Page
