import {message} from 'antd'
import reset from './reset'

function codeToMessage(code: number) {
  let message = `[${code}] `
  switch (code) {
    case 401:
      message = '没有授权'
      break
    case 403: {
      message = '授权签名不合法'
      break
    }
    case 404: {
      message = '没有找到内容'
      break
    }
    default:
      message += '系统错误'
  }
  return message
}

function errorResponseHandler(error: any) {
  // check for errorHandle config
  // if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
  //   return Promise.reject(error)
  // }
  // TODO: 上面的判断吞掉了错误处理，下面任何情况都向外reject
  // if has response show the error
  if (error.response) {
    const {data} = error.response
    const errorMessage = data.message || data.msg || codeToMessage(error.response.status)
    if (errorMessage) {
      message.error(errorMessage)
    }
    switch (error.response.status) {
      case 401:
      case 403:
        reset()
        break
      default:
    }
  }
  // and give back error
  return Promise.reject(error)
}

export default errorResponseHandler
