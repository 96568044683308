export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME
export const UPLOAD_IMAGE_ENDPOINT = process.env.REACT_APP_UPLOAD_IMAGE_ENDPOINT
export const UPLOAD_IMAGE_AUTH_HEADER_NAME = process.env.REACT_APP_UPLOAD_IMAGE_AUTH_HEADER_NAME
export const UPLOAD_IMAGE_ACCEPT = process.env.REACT_APP_UPLOAD_IMAGE_ACCEPT
// eslint-disable-next-line max-len
export const UPLOAD_IMAGE_SIZE_LIMIT = Number(process.env.REACT_APP_UPLOAD_IMAGE_SIZE_LIMIT) || 1048576
export const UPLOAD_IMAGE_RESPONSE_NAME = process.env.REACT_APP_UPLOAD_IMAGE_RESPONSE_NAME || 'file'
// export const CITI_INHOUSE_API_ENDPOINT = process.env.REACT_APP_CITI_INHOUSE_API_ENDPOINT
// export const POLICY_API_ENDPOINT = process.env.REACT_APP_POLICY_API_ENDPOINT

export const WEBSITE_TITLE_TEMPLATE = `%s - ${WEBSITE_NAME}`
export const BASE_PAGINATION = {
  pageSizeOptions: ['10', '20', '50', '100'],
  showQuickJumper: true,
  showSizeChanger: true,
  showTotal(total: number) {
    return `共 ${total} 项`
  }
}
