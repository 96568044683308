import {internalClient} from 'api/clients'
import {HUKOU_AUTH_TAG} from '../CONST'

export const READ_HUKOU_AUTH_TOKEN = 'READ_HUKOU_AUTH_TOKEN'
export const WRITE_HUKOU_AUTH_TOKEN = 'WRITE_HUKOU_AUTH_TOKEN'
export const RESET_HUKOU_AUTH_TOKEN = 'RESET_HUKOU_AUTH_TOKEN'
export const initialHukouAuthState = {
  hukouAuth: null
}
export const hukouAuthReducer: IReducer = (state, action) => {
  switch (action.type) {
    case READ_HUKOU_AUTH_TOKEN: {
      const auth = localStorage.getItem(HUKOU_AUTH_TAG)
      return {
        ...state,
        token: auth || false
      }
    }
    case WRITE_HUKOU_AUTH_TOKEN:
      if (action.token) {
        internalClient.defaults.headers.Authorization = `${action.token}`
        window.localStorage.setItem(HUKOU_AUTH_TAG, action.token)
      }
      return {
        ...state,
        token: localStorage.getItem(HUKOU_AUTH_TAG)
      }
    case RESET_HUKOU_AUTH_TOKEN:
      window.localStorage.removeItem(HUKOU_AUTH_TAG)
      return {
        ...state,
        token: false
      }
    default:
      return state
  }
}

export default hukouAuthReducer
