/* eslint-disable react/jsx-props-no-spreading */
import React, {FunctionComponent, useEffect} from 'react'
import {Route, Redirect} from 'react-router-dom'
import {READ_AUTH_TOKEN} from '../../reducer/auth'
import {useGlobalState} from '../../hooks/useGlobalState'
import {Loading} from '..'

interface IProtectedRoute {
  [x: string]: any
}
const ProtectedRoute: FunctionComponent<IProtectedRoute> = ({
  component, auther, path, exact, key, ...otherProps
}) => {
  const [{auth}, dispatch] = useGlobalState()
  // const auth = {
  //   token: 'test'
  // }
  useEffect(() => dispatch(READ_AUTH_TOKEN), [dispatch])
  const Component = component
  return (
    <Route
      key={key}
      path={path}
      exact={exact}
      // component={component}
      render={({match, history}) => {
        return (
          auther ? (
            <Component
              {...otherProps}
              match={match}
              history={history}
            />
          ) : (
            (auth && auth.token) ? (
              <Component
                {...otherProps}
                match={match}
                history={history}
              />
            ) : auth ? (
              <Redirect to="/auth" />
            ) : <Loading />
          )
        )
      }}
    />
  )
}

export default ProtectedRoute
