/* eslint-disable import/prefer-default-export */
import Axios from 'axios'

import {AUTH_TAG, HUKOU_AUTH_TAG} from 'CONST'
import errorResponseHandler from './errorhandler'
import codeHandler from './codeHandler'

export const jdClient = Axios.create({
  baseURL: process.env.REACT_APP_JD_API,
  headers: {
    Authorization: localStorage.getItem(AUTH_TAG),
    'Content-Type': 'application/json'
  }
})
export const internalClient = Axios.create({
  baseURL: process.env.REACT_APP_JD_API,
  headers: {
    Authorization: `${localStorage.getItem(HUKOU_AUTH_TAG)}`,
    'Content-Type': 'application/json'
  }
})
export const flagrClient = Axios.create({
  baseURL: process.env.REACT_APP_FLAGR_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
})

jdClient.interceptors.response.use(codeHandler, errorResponseHandler)
internalClient.interceptors.response.use(codeHandler, errorResponseHandler)
